import React from 'react';
import { Outlet, redirect } from "react-router-dom";
import Steps from "./components/Steps";
import { gql } from "@apollo/client";

export async function ensureUserIsAuthenticatedAndOnboardingIsNotCompleted(context, forceLastStep = true) {
  try {
    const result = await context.apolloClient.query({
      query: gql`
        query GetEnsureUserAndNotCompleted {
          me {
            onBoardedTeamAt
          }
        }
      `,
      fetchPolicy: "network-only",
    });

    if (null === result.data.me) {
      throw redirect("/join/team");
    }

    if (null !== result.data.me.onBoardedTeamAt && forceLastStep) {
      throw redirect("/join/team/what-now");
    }

    return true;
  } catch (e) {
    if ('unauthorized' === e.message) {
      throw redirect("/join/team");
    }

    throw e;
  }
}

export default function Team() {
  return (
    <>
      <Steps/>
      <Outlet/>
    </>
  );
}
